<template>
  <v-layout>
    <v-flex class="py-2 px-5">
      <v-row>
        <v-col cols="12">
          <v-card class="pa-5" tile>
            <QueryRenderer :cubejs-api="cubejsApi" :query="queryCustomers">
              <template v-slot="{ loading, resultSet }">
                <v-select
                  v-if="resultSet.loadResponse.results[0].data.length"
                  :items="resultSet.loadResponse.results[0].data"
                  label="Filter by Customer"
                  v-model="customer"
                  item-text="Customers.name"
                  item-value="Customers.id"
                  @change="filterCubeQuery($event)"
                  :loading="loadingData"
                  clearable
                >
                </v-select>
              </template>
            </QueryRenderer>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-5" tile>
            <div class="title ml-7 mb-5">Items sold in the last 3 months</div>
            <column-chart
              v-if="threeMonthData"
              :class="!threeMonthData.length ? 'chart no-data' : 'chart'"
              legend="bottom"
              :colors="[
                '#FAA225',
                '#e6194B',
                '#3cb44b',
                '#ffe119',
                '#4363d8',
                '#f58231',
                '#911eb4',
                '#42d4f4',
                '#f032e6',
                '#bfef45',
                '#fabed4',
                '#469990',
                '#dcbeff',
                '#9A6324',
                '#fffac8',
                '#800000',
                '#aaffc3',
                '#808000',
                '#ffd8b1',
                '#000075',
                '#a9a9a9',
                '#ffffff',
                '#000000',
              ]"
              :data="threeMonthData"
              :messages="{ empty: 'No data available' }"
            ></column-chart>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-5" tile>
            <div class="title ml-7 mb-5">Items sold this year per month</div>
            <column-chart
              v-if="breakdownData"
              :class="!breakdownData.length ? 'chart no-data' : 'chart'"
              legend="bottom"
              :colors="[
                '#FAA225',
                '#e6194B',
                '#3cb44b',
                '#ffe119',
                '#4363d8',
                '#f58231',
                '#911eb4',
                '#42d4f4',
                '#f032e6',
                '#bfef45',
                '#fabed4',
                '#469990',
                '#dcbeff',
                '#9A6324',
                '#fffac8',
                '#800000',
                '#aaffc3',
                '#808000',
                '#ffd8b1',
                '#000075',
                '#a9a9a9',
                '#ffffff',
                '#000000',
              ]"
              :data="breakdownData"
              :stacked="true"
              :messages="{ empty: 'No data available' }"
            ></column-chart>
          </v-card>
        </v-col>
      </v-row>
    </v-flex>
  </v-layout>
</template>

<script>
  import cubejs from "@cubejs-client/core";
  import jwt from "jsonwebtoken";
  import { QueryRenderer } from "@cubejs-client/vue";

  export default {
    components: {
      QueryRenderer,
    },
    mounted: async function () {
      this.filterCubeQuery(null);
    },
    data: function () {
      const cubejsApiToken = jwt.sign(
        { supplierId: this.$store.state.shops.activeShop.id },
        process.env.VUE_APP_CUBEJS_API_SECRET,
        {
          expiresIn: "30d",
        }
      );
      //  Our Cube.js Key and API URL
      const cubejsApi = cubejs(cubejsApiToken, {
        apiUrl: `${process.env.VUE_APP_CUBEJS_API_URL}/cubejs-api/v1`,
      });

      const threeMonthData = null;
      const breakdownData = null;

      const loadingData = false;
      const customer = null;

      return {
        cubejsApi,
        loadingData,
        customer,
        threeMonthData,
        breakdownData,
        queryCustomers: {
          measures: ["Orders.count"],
          dimensions: ["Customers.id", "Customers.code", "Customers.name"],
          order: {
            "Customers.name": "asc",
          },
          timezone: "Africa/Johannesburg",
        },
      };
    },
    methods: {
      loadCubeQuery: async function (query) {
        return await this.cubejsApi.load(query);
      },
      filterCubeQuery: async function (customerId) {
        this.loadingData = true;
        const customer = customerId;

        // Items sold in the last 3 months
        const timeDimensions1 = [
          {
            dimension: "LineItems.orderPaymentDateTime",
            dateRange: "Last 3 months",
          },
        ];
        const order1 = { "LineItems.quantity": "desc" };

        const query1 = await this.queryItemsSold(
          customer,
          timeDimensions1,
          order1
        );
        const resultSet1 = await this.loadCubeQuery(query1);
        this.series(resultSet1);

        // Items sold in breakdown per month
        const timeDimensions2 = [
          {
            dimension: "LineItems.orderPaymentDateTime",
            granularity: "month",
            dateRange: "This year",
          },
        ];
        const order2 = {
          "LineItems.orderPaymentDateTime": "asc",
          "LineItems.quantity": "desc",
        };

        const query2 = await this.queryItemsSold(
          customer,
          timeDimensions2,
          order2
        );
        const resultSet2 = await this.loadCubeQuery(query2);
        this.seriesPerMonth(resultSet2);
      },

      queryItemsSold: async function (customer, timeDimensions, order) {
        const measures = ["LineItems.quantity"];
        const dimensions = [
          "LineItems.productName",
          "LineItems.productShortDescription",
        ];
        let customerFilter = customer
          ? [
              {
                dimension: "Customers.id",
                operator: "equals",
                values: [customer],
              },
            ]
          : [];
        const filters = customerFilter;
        const limit = 100;

        return {
          measures,
          timeDimensions,
          filters,
          dimensions,
          limit,
          order,
        };
      },
      series: function (resultSet) {
        const seriesNames = resultSet.seriesNames();
        const pivot = resultSet.chartPivot();
        const series = [];
        if (seriesNames) {
          seriesNames.forEach((e) => {
            const data = pivot.map((p) => [p.x, p[e.key]]);
            series.push({ name: "Quantity", data });
          });
          this.threeMonthData = series;
        }
      },
      seriesPerMonth: function (resultSet) {
        const seriesNames = resultSet.seriesNames();
        const pivot = resultSet.chartPivot();
        const series = [];
        const months = {
          "01": "Jan",
          "02": "Feb",
          "03": "Mar",
          "04": "Apr",
          "05": "May",
          "06": "Jun",
          "07": "Jul",
          "08": "Aug",
          "09": "Sep",
          10: "Oct",
          11: "Nov",
          12: "Dec",
        };
        if (seriesNames) {
          seriesNames.forEach((e) => {
            const data = pivot.map((p) => [months[p.x.slice(5, 7)], p[e.key]]);
            series.push({
              name: e.title.split(", Order Line Items Quantity")[0],
              data,
            });
          });
          this.loadingData = false;
          this.breakdownData = series;
        }
      },
    },
  };
</script>

<style scoped>
  .img-responsive {
    max-width: 100%;
    vertical-align: middle;
  }

  .chart {
    height: 538px !important;
  }

  .chart.no-data {
    height: 100px !important;
    line-height: 100px !important;
  }
</style>
