var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-flex',{staticClass:"py-2 px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5",attrs:{"tile":""}},[_c('QueryRenderer',{attrs:{"cubejs-api":_vm.cubejsApi,"query":_vm.queryCustomers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var resultSet = ref.resultSet;
return [(resultSet.loadResponse.results[0].data.length)?_c('v-select',{attrs:{"items":resultSet.loadResponse.results[0].data,"label":"Filter by Customer","item-text":"Customers.name","item-value":"Customers.id","loading":_vm.loadingData,"clearable":""},on:{"change":function($event){return _vm.filterCubeQuery($event)}},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}}):_vm._e()]}}])})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5",attrs:{"tile":""}},[_c('div',{staticClass:"title ml-7 mb-5"},[_vm._v("Items sold in the last 3 months")]),(_vm.threeMonthData)?_c('column-chart',{class:!_vm.threeMonthData.length ? 'chart no-data' : 'chart',attrs:{"legend":"bottom","colors":[
              '#FAA225',
              '#e6194B',
              '#3cb44b',
              '#ffe119',
              '#4363d8',
              '#f58231',
              '#911eb4',
              '#42d4f4',
              '#f032e6',
              '#bfef45',
              '#fabed4',
              '#469990',
              '#dcbeff',
              '#9A6324',
              '#fffac8',
              '#800000',
              '#aaffc3',
              '#808000',
              '#ffd8b1',
              '#000075',
              '#a9a9a9',
              '#ffffff',
              '#000000' ],"data":_vm.threeMonthData,"messages":{ empty: 'No data available' }}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5",attrs:{"tile":""}},[_c('div',{staticClass:"title ml-7 mb-5"},[_vm._v("Items sold this year per month")]),(_vm.breakdownData)?_c('column-chart',{class:!_vm.breakdownData.length ? 'chart no-data' : 'chart',attrs:{"legend":"bottom","colors":[
              '#FAA225',
              '#e6194B',
              '#3cb44b',
              '#ffe119',
              '#4363d8',
              '#f58231',
              '#911eb4',
              '#42d4f4',
              '#f032e6',
              '#bfef45',
              '#fabed4',
              '#469990',
              '#dcbeff',
              '#9A6324',
              '#fffac8',
              '#800000',
              '#aaffc3',
              '#808000',
              '#ffd8b1',
              '#000075',
              '#a9a9a9',
              '#ffffff',
              '#000000' ],"data":_vm.breakdownData,"stacked":true,"messages":{ empty: 'No data available' }}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }